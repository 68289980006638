<template>
	<div id="pdfDom">
		<div class="page">

			<div class="resultArea">
				<div class="reportTxt">心理健康测评报告</div>
			</div>
			<div class="nameArea">
				<div class="nameLabel">
					姓名
				</div>
				<div class="nameText">
					{{dataForm.patientName}}
				</div>
			</div>
			<div style="display: flex;">
				<div class="patientCard">
					<div class="line">
						<div class="label">学生编号</div>
						<div class="content">123</div>
					</div>
					<div class="line">
						<div class="label">测评时间</div>
						<div class="content">123</div>
					</div>
					<div class="line">
						<div class="label">套餐名称</div>
						<div class="content">123</div>
					</div>
				</div>
				<img style="width: 688rem;height: 620rem;position: relative;margin-top: -352rem;"
					src="../../assets/img/result/zhanwei.png" alt="" />
			</div>
			<div class="chartCard">
				<div class="chartTitle">测评结果</div>
				<div class="chartArea">
					<div style="width: 570rem;height: 500rem;margin-left: calc(50% - 250rem);" id="main"></div>
				</div>
			</div>
			<div class="cpjjLabel">
				测评简介
			</div>


			<div>
				{{dataForm.totalScore}}{{dataForm.patientName}}您当前处于{{dataForm.totalResult}}
				<!-- <template v-for="item in formList">
					<div class="line1">
						<div class="myScore">
							<img v-if="dataForm.totalScore>=item.score1&&dataForm.totalScore<item.score2"
								style="width: 4.2rem;height: 4.2rem;margin-right: 2.6rem;"
								src="../../assets/img/result/gou.png" mode=""></img>
							<div v-else style="width: 4.2rem;height: 4.2rem;margin-right: 2.6rem;"></div>
							<div style="width:23.8rem ;">{{item.score1}}~{{item.score2}}分</div>

						</div>
						<div class="myIllness">{{item.cpjg}}</div>
					</div>
					<div class="fengexian"></div>
				</template> -->

			</div>

			<div>
				{{dataForm.scoreAnalysis}}
			</div>



			<div>
				{{dataForm.scoreAdvice}}
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from "echarts";
	export default {
		data() {
			return {
				dataForm: {
					totalScore: '',
					scoreAnalysis: '',
					totalResult: '',
					scoreAdvice: '',
				},
				formList: [],
				menuFlag: false,
				chooseIndex: 0,
				totalPage: '',
				title: 'Hello',
				footSize: this.$footFontSize,
				name: '',
				idCard: '',
				pageSize: 8,
				pageNo: 1,
				dataList: []
			}
		},
		onLoad() {



		},
		mounted() {

			this.getInfo()
		},
		methods: {
			async getInfo() {
				let itemObj = JSON.parse(sessionStorage.result)
				this.dataForm.patientName = itemObj.patientName
				this.dataForm.totalScore = itemObj.totalScore
				let params = {
					stringParam1: JSON.parse(sessionStorage.result).packageId
				};

				// if(localStorage.getItem('currentTitle')=='康复时空健康管理'&&localStorage.getItem('login'))
				const {
					data: res
				} = await this.$httpAes({
					url: this.$httpAes.adornUrl("evaluationPackage/info"),
					method: "post",
					data: params,
					contentType: 'json',
					headers: {
						'Content-Type': 'application/json',

					},
				});
				console.log('evaluationPackage/info')
				console.log(res.data)
				if (res.data.packageRule && res.data.packageRule.length > 0) {
					this.formList = JSON.parse(res.data.packageRule)
					console.log(this.formList)
					if (itemObj.totalResult && itemObj.totalResult.length > 0) {
						this.dataForm.totalResult = itemObj.totalResult
						this.dataForm.scoreAnalysis = itemObj.scoreAnalysis
						this.dataForm.scoreAdvice = itemObj.scoreAdvice
					} else {
						for (let item of this.formList) {
							if (this.dataForm.totalScore >= item.score1 && this.dataForm.totalScore <= item
								.score2) {

								this.dataForm.totalResult = item.cpjg
								this.dataForm.scoreAnalysis = item.jgfx
								this.dataForm.scoreAdvice = item.tzjy
							}
						}
					}
					let diseaseType = JSON.parse(res.data.diseaseType)
					for (let item of diseaseType) {
						item.max = 100
					}
					let dataObj = {
						name: this.dataForm.patientName,
						value: []
					}
					if (JSON.parse(sessionStorage.evaluationRecDetail).totalScore && JSON.parse(sessionStorage
							.evaluationRecDetail).totalScore.length > 0) {
						this.leidaFlag = true
						let totalScore = JSON.parse(sessionStorage.evaluationRecDetail).totalScore
						for (let index in totalScore) {
							dataObj.value.push(totalScore[index].score)

						}
					}
					console.log('!!!!!!!!!dataObj')
					console.log(dataObj)
					var myChart = echarts.init(document.getElementById('main'));

					let option = {
						// backgroundColor:'#ffffff',

						tooltip: {
							confine: true,
							enterable: true,

							trigger: 'axis'
						},
						radar: [{
								"name": {
									"textStyle": {
										"color": 'rgba(0,0,0,0.45)'
									}
								},
								splitArea: {
									areaStyle: {
										color: ['#fff', '#fff']
									}
								},
								// shape: 'circle',
								indicator: diseaseType,
								center: ['50%', '50%'],

							},

						],

						series: [{
								name: '雷达图',
								type: 'radar',
								areaStyle: {
									// 填充区颜色
									color: 'rgb(227,238,254)',
								},
								//   radarIndex: 1,
								tooltip: {
									trigger: 'item',

								},
								//   areaStyle: {},

								itemStyle: {
									color: 'color: rgba(0,0,0,0.45);',
									normal: {
										color: '#1890FF',
										lineStyle: {
											width: 2
										},
										opacity: 0.6
									},
									emphasis: {
										lineStyle: {
											width: 2
										},
										opacity: 1
									},
								},
								data: [dataObj]
							},


						],
					};

					myChart.setOption(option);
					console.log(this.dataForm)
					setTimeout(() => {
						this.exportReport()
					}, 1000)
				}

			},
			exportReport() {
				console.log('exportReport')
				const target = document.getElementById('pdfDom')
				this.outPutPdfFn('pdf文件名', target, 'item')
			},
			outPutPdfFn(title, target, itemClass) {
				const vm = this
				const A4_WIDTH = 592.28
				const A4_HEIGHT = 841.89

				vm.$nextTick(() => {
					// dom的id。

					const pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT
					// 获取分割dom，此处为class类名为item的dom
					const lableListID = document.getElementsByClassName(itemClass)
					// 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
					for (let i = 0; i < lableListID.length; i++) {
						const multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) /
							pageHeight)
						if (this.isSplit(lableListID, i, multiple * pageHeight)) {
							const divParent = lableListID[i].parentNode // 获取该div的父节点
							const newNode = document.createElement('div')
							newNode.className = 'emptyDiv'
							newNode.id = 'emptyDiv'
							newNode.style.background = '#ffffff'
							const _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i]
								.offsetHeight)
							newNode.style.height = _H + 30 + 'px'
							newNode.style.width = '100%'
							const next = lableListID[i].nextSibling // 获取div的下一个兄弟节点
							// 判断兄弟节点是否存在
							console.log(next)
							if (next) {
								// 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
								divParent.insertBefore(newNode, next)
							} else {
								// 不存在则直接添加到最后,appendChild默认添加到divParent的最后
								divParent.appendChild(newNode)
							}
						}
					}
					// 传入title和dom标签，此处是 #content
					// 异步函数，导出成功后处理交互
					this.getPdf(title, target, 'emptyDiv').then(() => {
						// 自定义等待动画关闭
						this.$message({
							type: 'success',
							message: '导出成功'
						})
						this.detailSHow = false
					})
					this.$nextTick(() => {
						const emptyDom = document.getElementById('emptyDiv')
						emptyDom.remove()
					})


				})
			},
			isSplit(nodes, index, pageHeight) {
				// 计算当前这块dom是否跨越了a4大小，以此分割
				if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1]
					.offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
					return true
				}
				return false
			},

			changeChoose(index) {
				this.chooseIndex = index
			},
			goHome() {
				uni.navigateTo({
					url: '/pages/index/index'
				})
			},
			showMenu() {
				this.menuFlag = true
			},
			lastPage() {
				if (this.pageNo != 1) {
					this.pageNo--
					this.getDataList()
				}
			},
			nextPage() {
				if (this.pageNo != this.totalPage) {
					this.pageNo++
					this.getDataList()
				}
			},
			getTaskInfo() {

				var params = {
					stringParam1: uni.getStorageSync('patientInfo').idCard,
					//	stringParam1	:'123',					
					stringParam2: this.dataList[this.chooseIndex].id,
					stringParam3: uni.getStorageSync('userInfo').id,

				}

				return new Promise((resolve, reject) => {
					uni.request({
						url: dominUrl() + '/diagnosisTask/taskInfo', //仅为示例，并非真实接口地址。POST /user/mobileLogin
						data: encryptData(params),
						method: 'post',
						header: {
							'token': uni.getStorageSync('token'),
							'custom-header': 'hello' //自定义请求头信息
						},
						success: (res) => {
							console.log(res)
							let data = res.data
							var mydata = data.body.data;
							var aesKey = data.body.aesKey;
							let obj = decryptData(mydata, aesKey)

							if (obj.status) {
								//uni.hideLoading()

								resolve(obj)



							} else {

								uni.showToast({
									title: obj.msg,
									icon: 'none'
								})
								reject('error')
							}
						}
					});
				})



			},
			async submit() {
				// 手机录视频和调用手机视频功能
				uni.showLoading({
					title: '加载中'
				});
				await this.getTaskInfo().then(res => {
					console.log('getTaskInfo')
					console.log(res)

					if (res.data) {
						uni.setStorageSync('taskInfo', res.data)
						uni.hideLoading()
						uni.navigateTo({
							url: '/pages/evaluation/chooseEvaluation'
						})
					} else {
						var year = date.getFullYear()

						var month = date.getMonth() + 1;

						var day = date.getDate();

						var hour = date.getHours();

						var minute = date.getMinutes();

						var second = date.getSeconds();



						month = (month < 10 ? "0" + month : month);

						day = (day < 10 ? "0" + day : day);
						hour = (hour < 10 ? "0" + hour : hour);
						minute = (minute < 10 ? "0" + minute : minute);
						second = (second < 10 ? "0" + second : second);




						var params = {

							patientName: uni.getStorageSync('patientInfo').name,

							patientCard: uni.getStorageSync('patientInfo').idCard,

							packageId: this.dataList[this.chooseIndex].id,
							startTime: year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" +
								second
						}


						console.log('diagnosisTask/add')

						uni.request({
							url: dominUrl() +
								'/diagnosisTask/add', //仅为示例，并非真实接口地址。POST /user/mobileLogin
							data: encryptData(params),
							method: 'post',
							header: {
								'token': uni.getStorageSync('token'),
								'custom-header': 'hello' //自定义请求头信息
							},
							success: (res) => {
								console.log(res)
								let data = res.data
								var mydata = data.body.data;
								var aesKey = data.body.aesKey;
								let obj = decryptData(mydata, aesKey)
								console.log(obj)
								if (obj.status) {
									uni.setStorageSync('taskInfo', obj.data)
									uni.hideLoading()

									uni.setStorageSync('packageDetail', this.dataList[this
										.chooseIndex])
									uni.navigateTo({
										url: '/pages/evaluation/chooseEvaluation'
									})
								} else {

									uni.showToast({
										title: obj.msg,
										icon: 'none'
									})
								}
							}
						});

					}

				})







			},
			goBack() {
				uni.navigateTo({
					url: '/pages/login/user'
				})
			},
			// 提交表单
			getDataList() {

				var params = {
					pageSize: this.pageSize,
					pageNo: this.pageNo,
				}
				console.log(params)
				uni.showLoading({
					title: '加载中'
				});
				uni.request({
					url: dominUrl() + '/evaluationPackage/list', //仅为示例，并非真实接口地址。POST /user/mobileLogin
					data: encryptData(params),
					method: 'post',
					header: {
						'token': uni.getStorageSync('token'),
						'custom-header': 'hello' //自定义请求头信息
					},
					success: (res) => {
						console.log(res)
						let data = res.data
						var mydata = data.body.data;
						var aesKey = data.body.aesKey;
						let obj = decryptData(mydata, aesKey)
						console.log(obj)
						if (obj.status) {
							uni.hideLoading()

							this.dataList = obj.data
							this.totalPage = obj.totalPage
						} else {

							uni.showToast({
								title: obj.msg,
								icon: 'none'
							})
						}
					}
				});


			},
		}
	}
</script>

<style scoped lang="scss">
	.page {

		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding-top: 106rem;
		background: url("../../assets/img/result/bag.png") center center no-repeat;
		background-size: 100% 100%;

	}

	.chartCard {
		margin-top: 130rem;
		width: calc(100% - 192rem);
		height: 827rem;
		margin-left: 96rem;
		text-align: center;
		box-sizing: border-box;
		padding-top: 67rem;
		padding-bottom: 50rem;
		background: url("../../assets/img/result/chartCard.png") center center no-repeat;
		background-size: 100% 100%;

		.chartTitle {
			font-family: Helvetica;
			font-size: 80rem;
			color: #FFFFFF;
			line-height: 110rem;
			margin-bottom: 30rem;
		}
	}
.cpjjLabel{
	font-family: Helvetica;
	font-size: 80rem;
	color: #097BFF;
	line-height: 96rem;
	text-stroke: 1rem #FFFFFF;
	text-align: left;
	font-style: normal;
}
	.chartArea {
		width: calc(100% - 136rem);
		height: 570rem;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.47) 21%, rgba(255, 255, 255, 0.88) 36%, #FFFFFF 49%, rgba(255, 255, 255, 0.88) 64%, rgba(255, 255, 255, 0.47) 79%, rgba(255, 255, 255, 0) 100%);
		border-radius: 24px;
		margin-left: 68rem;
		padding-top: 34rem;
		box-sizing: border-box;
	}

	.resultArea {
		width: 1600rem;
		height: 411rem;
		box-sizing: border-box;

		margin-left: 96rem;
		margin-bottom: 57rem;
		background: url("../../assets/img/result/Report.png") center center no-repeat;

		background-size: 100% 100%;
	}

	.patientCard {
		width: 994rem;
		height: 268rem;
		margin-left: 200rem;
		margin-right: 40rem;
		background: rgba(255, 255, 255, 0.22);
		border-radius: 15px;
		box-sizing: border-box;
		padding: 30rem 40rem;

		.line {
			display: flex;
			margin-bottom: 20rem;

			.label {
				font-family: PingFangSC, PingFang SC;
				font-weight: 400;
				font-size: 40rem;
				color: #0065A4;
				line-height: 56rem;
				margin-right: 50rem;
			}

			.content {
				font-family: PingFangSC, PingFang SC;
				font-weight: 500;
				font-size: 40rem;
				color: #009DFF;
				line-height: 56rem;
			}
		}
	}

	.nameArea {
		position: relative;
		display: flex;
		width: 558rem;
		height: 100rem;
		margin-left: 200rem;
		margin-bottom: 82rem;
		border-radius: 57px;

		border: 6rem solid;

		border-color: #1E61F4;

	}

	.nameLabel {
		width: 277rem;
		height: 88rem;
		line-height: 88rem;
		text-align: center;
		background: #1E61F4;
		/* 	background:linear-gradient( 180deg, #409AFA 0%, #1E61F4 100%); */
		border-radius: 57px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 50rem;
		color: #FFFFFF;

		text-shadow: 0px 2rem 8rem #083D85;
	}

	.nameText {
		width: 277rem;
		height: 88rem;
		line-height: 88rem;
		text-align: center;

		border-radius: 57px;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		font-size: 50rem;
		color: #FFFFFF;

	}

	.reportTxt {
		font-family: Helvetica;
		font-size: 150rem;
		color: #1E61F4;
		line-height: 180rem;
		text-shadow: 0px 2rem 4rem #195DCC;
		text-align: left;
		font-style: normal;
		padding-left: 100rem;
		padding-top: 182rem;
	}

	.textArea {
		background: #F9F1FF;
		font-size: 3.6rem;
		font-family: AlibabaPuHuiTiR;
		color: #333333;
		line-height: 5.4rem;
		width: 100%;
		box-sizing: border-box;
		padding: 0 9.6rem 8rem 9.6rem;
	}

	.textArea2 {
		background: #F9F1FF;
		font-size: 3.6rem;
		font-family: AlibabaPuHuiTiR;
		color: #333333;
		line-height: 5.4rem;
		width: 100%;
		box-sizing: border-box;

	}

	.scoreTable {
		width: 107.0rem;
		height: 56.4rem;
		background: rgba(239, 212, 255, 0.37);
		border-radius: 16px;
		box-sizing: border-box;
		padding: 8rem 12rem;
		margin-top: 7.2rem;
		margin-left: calc(50% - 53.5rem);
	}

	.myScore {

		display: flex;
		align-items: center;

		font-size: 4.0rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #A977F3;
		/* margin-right: 15rem; */
	}

	.myIllness {
		width: 50%;
		text-align: center;
		font-size: 4.0rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.line1 {
		height: 11.6rem;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
	}

	.fengexian {
		width: 100%;
		height: 1px;
		background-image: linear-gradient(to right,
				#BC5EFF 0%,
				#BC5EFF 50%,
				transparent 50%);
		background-size: 20px 2px;
		/*  //第一个值（20px）越大线条越长间隙越大 */
		background-repeat: repeat-x;
	}

	.line2 {
		height: 8rem;
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		/* 	border-top:   5px 3px dashed #BC5EFF;
	border-bottom:   5px 3px dashed #BC5EFF; */

		border-bottom: 10px dashed red;
		border-spacing: 50px;
	}

	.nametxt {
		height: 11.2rem;
		font-size: 8rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 11.2rem;
		text-shadow: 0px 2px 8px #65229E;
		margin-bottom: 3.6rem;
	}

	.illtxt {
		height: 8.4rem;
		font-size: 6rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 8.4rem;
		text-shadow: 0px 2px 8px #65229E;
	}



	.score {
		width: 40rem;
		height: 40rem;
		background: url("../../assets/img/result/score.png") center center no-repeat;
		background-size: 100% 100%;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.jgfxtxt {
		height: 11.0rem;
		font-size: 8rem;
		font-family: AlibabaPuHuiTiB;
		color: #A977F3;
		line-height: 11.0rem;

	}

	.jgfx {
		/* display: flex;
		flex-direction: column;
		align-items: center; */
		height: 1080rem;
		box-sizing: border-box;
		background: url("../../assets/img/result/mid.png") center center no-repeat;
		background-size: 100% 100%;
		padding: 6rem 9.6rem;
	}

	.tzjy {

		box-sizing: border-box;
		width: 100%;
		background: #F9F1FF;
		padding: 6rem 9.6rem;
	}

	.tzjytxt {

		font-size: 8rem;
		font-family: AlibabaPuHuiTiB;
		color: #A977F3;
		line-height: 11.0rem;
		margin-bottom: 7rem;

	}

	.pageNo {
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
	}

	.menu {
		height: 5.6rem;
		font-size: 4.0rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 5.6rem;
		text-shadow: 0px 2px 2px #083D85;
		;
	}

	.yema {
		margin: 0 2rem;
		height: 5.5rem;
		font-size: 3.6rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 5.5rem;
	}

	.fanye {
		width: 15.0rem;
		height: 5.5rem;
		line-height: 5.5rem;
		font-size: 3.2rem;
		text-align: center;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		border-radius: 4px;
		border: 2px solid #FFFFFF;
	}

	.noright {
		margin-right: 0 !important;
	}

	.sel-icon {
		width: 4.6rem;
		height: 4.6rem;
		position: absolute;
		right: 2rem;
		top: 2rem;
	}

	.choosePackageItem {
		position: relative;
		width: 40.6rem;
		height: 18.1rem;
		background: linear-gradient(180deg, #F163A0 0%, #FFA7D5 100%);
		border-radius: 4px;
		margin-right: 3.5rem;
		margin-bottom: 4rem;
	}



	.backLine {
		width: 100%;
		display: flex;
		justify-content: space-between;

	}

	.packageName {
		margin-top: 6.8rem;
		margin-left: 2rem;
		width: 36.6rem;
		height: 4.5rem;
		font-size: 3.2rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #333333;
		line-height: 4.5rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.packageName2 {
		margin-top: 6.8rem;
		margin-left: 2rem;
		width: 36.6rem;
		height: 4.5rem;
		font-size: 3.2rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #fff;
		line-height: 4.5rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.qxzjctc {
		height: 5.6rem;
		font-size: 4.0rem;
		font-family: PingFangSC, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 5.6rem;
		text-align: center;
		margin-top: 3rem;
		margin-bottom: 4rem;
	}

	.img1 {
		width: 4.8rem;
		height: 4.8rem;
		margin-right: .4rem;

	}

	.img2 {
		width: 7.9rem;
		height: 4.1rem;
	}

	.img3 {
		width: 15.9rem;
		height: 4.1rem;
	}

	.title {
		height: 5.6rem;
		font-size: 4.0rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 5.6rem;
		margin-top: 2.8rem;
		margin-bottom: 3rem;
		width: 100%;
		text-align: center;
	}

	.cardArea {
		width: 121.6rem;
		height: 53rem;

		box-sizing: border-box;
		background: url("../../assets/img/login/card.png") center center no-repeat;
		background-size: 100% 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.inputArea {
		width: 82.0rem;
		height: 9.5rem;
		line-height: 9.5rem;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EEEEEE;
		display: flex;
		align-items: center;
	}

	.icon {
		width: 4rem;
		height: 4rem;
		margin-left: 2rem;
		margin-right: 2rem;
	}

	input {
		width: 70rem;
		height: 4rem;
	}

	.logo {
		height: 9.7rem;
		width: 9.7rem;
		margin-bottom: 9.9rem;

	}

	.jingshen {
		width: 138.5rem;
		height: 9.8rem;
		margin-bottom: 5.2rem;
	}

	.toubu {
		width: 86.3rem;
		height: 11.3rem;
		margin-bottom: 4.3rem;
	}

	.cxContent {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 49.9rem;
		height: 7.3rem;
		background: rgba(215, 190, 247, 0.36);
		border-radius: 8px;
		border: 1px solid #FFFFFF;
		margin-bottom: 10rem;
	}

	.chuangxin {
		width: 41.4rem;
		height: 3.9rem;
	}

	.buttonLine {
		display: flex;

	}

	.mybutton {
		width: 28.8rem;
		height: 10rem;
		background: linear-gradient(180deg, #FFF05D 0%, #FF9605 100%);
		box-shadow: 0px 2px 4px 0px #A57A00;
		border-radius: 8px;

		text-align: center;
		font-size: 5.0rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 10rem;
	}

	.text-area {
		display: flex;
		justify-content: center;
	}
</style>